import React, { useState, useEffect } from 'react'
import IndexPage from './IndexPage';


export default function Home() {
    // Get location 
    const [location, setLocation] = useState({ latitude: null, longitude: null });

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // Handle permission denied
                        console.error("Location access denied by the user.");
                    } else {
                        console.error("Error getting location:", error);
                    }
                }
            );
        } else {
            console.error("Geolocation is not available in this browser.");
        }
    }, []);

    return (
        <div className="grid sm:grid-cols-2 font-[Inter] h-screen relative bg-white">
            {/* <InstallPWA /> */}
            <IndexPage />
            {/* {location.latitude ? <IndexPage /> :
                <div className=' flex  flex-col items-center justify-center'>

                    <h1 className=' select-none'>Please allow Location and try again </h1>
                    <div className='px-8 py-2 mt-7 rounded-md bg-slate-200' onClick={() => { window.location.reload() }}>
                        <h1 className='select-none'>Reload</h1>
                    </div>

                </div>} */}
        </div>
    )
}
