import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./Style.css";


const AutoComplete = ({ data, onClick, title, preValue, isDisable, isError }) => {
  const [value, setValue] = React.useState(
    preValue === "" ? null : preValue
  );

  React.useEffect(() => {
    if (preValue === "") {
      setValue(null);
    }
  }, [preValue]);

  return (
    <div
      className={` border-[1px] border-[#A7A1A1] rounded-md ${isDisable && "bg-disable"
        }`}
    >
      {isDisable ? (
        <Autocomplete
          sx={{ width: "100%", height: "100%" }}
          disabled
          className="flex flex-grow bg-white border-2 rounded-md"
          value={value}
          // className="flex flex-grow"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, newValue) => {
            setValue(newValue);
            onClick(newValue);
          }}
          id="auto-complete"
          autoComplete
          options={!!data ? data : []}
          renderInput={(params) => (
            <TextField
              sx={{
                "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                  // fontSize: 14,
                },
                marginTop: 0.1,
                border: "0.5px",

                "& fieldset": {
                  borderBottomColor: "#A7A1A1",
                  border: "none",
                },
                "& label.Mui-focused": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='standard'
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='filled'
                "& .MuiFilledInput-underline:after": {
                  borderBottomColor: "#A7A1A1",
                },
                "& .MuiFilledInput-underline:focus": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='outlined'
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A7A1A1",
                  },
                },
              }}
              {...params}
              variant="outlined"
              hiddenLabel
              size="small"
            />
          )}
        />
      ) : (
        <Autocomplete
          className={`${isError && "border-[1px] border-red-600"} flex flex-grow bg-white border-2 rounded-md`}
          sx={{ width: "100%", height: "100%" }}
          value={value}
          // className="flex flex-grow"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, newValue) => {
            setValue(newValue);
            onClick(newValue);
          }}
          id="auto-complete"
          autoComplete
          options={!!data ? data : []}
          renderInput={(params) => (
            <TextField
              sx={{
                "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                  // fontSize: 14,
                },
                marginTop: 0.1,
                border: "0.5px",

                "& fieldset": {
                  borderBottomColor: "#A7A1A1",
                  border: "none",
                },
                "& label.Mui-focused": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='standard'
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='filled'
                "& .MuiFilledInput-underline:after": {
                  borderBottomColor: "#A7A1A1",
                },
                "& .MuiFilledInput-underline:focus": {
                  borderBottomColor: "#A7A1A1",
                },
                // focused color for input with variant='outlined'
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A7A1A1",
                  },
                },
              }}
              {...params}
              variant="outlined"
              hiddenLabel
              size="small"
            />
          )}
        />
      )}
    </div>
  );
};
export default AutoComplete;
