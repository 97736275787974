import React, { useEffect, useState } from 'react'
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    Page,
    Resize,
    Filter,
    Toolbar,
} from "@syncfusion/ej2-react-grids";
import "./dataGrid.css";
import EditIcon from "../../assets/Edit.svg"
import { useNavigate } from "react-router-dom";



const DataGrid = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();


    const key = JSON.parse(localStorage.getItem('user'))
    const GetSumbitedForms = async () => {
        await fetch("https://brick-klin.wbpcb.gov.in/api/form/", {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${key}`
            },
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => setData(result?.data?.map((item, index) => ({ ...item, idx: index + 1 }))))
            .catch(error => console.log('error', error));
    }

    useEffect(() => {

        GetSumbitedForms()

    }, [])

    const EditHandler = (args) => {
        // pending_status
        return <div className='items-center justify-center flex' onClick={() => {
            args?.pending_status !== "approved" && navigate("/addNew", { state: JSON.stringify(args) });

        }}>
            {args?.pending_status === "approved" ? "" :
                <img src={EditIcon} className='w-4 h-4' />}
        </div>
    }

    const StatusTemplate = (args) => {

        return <div className='w-full  h-8 rounded-md  uppercase flex items-center justify-center' style={{ backgroundColor: args.pending_status === "approved" ? "green" : "#ebebeb" }} >
            {args.pending_status}
        </div>

    }

    let grid;
    const toolbarOptions = ["Search"];
    return (
        <GridComponent dataSource={data} height={window.innerHeight * 0.2} width={window.innerWidth * 0.8}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={{ type: 'Excel' }}
            toolbar={toolbarOptions}
            ref={(g) => (grid = g)}>
            <ColumnsDirective >
                <ColumnDirective field="idx" width="90" headerText='ID' />
                {/* <ColumnDirective field="user_id" width="150" headerText=' CSC ID' /> */}
                <ColumnDirective field="enterprise_name" width="200" headerText='Enterprise Name' />
                <ColumnDirective field="owner_name" width="200" headerText='Owner Name' />
                <ColumnDirective field="owner_mobile" width="200" headerText='Owner Number' />
                <ColumnDirective field="trade_license_number" width="200" headerText='Tread License No.' />

                {/* <ColumnDirective field="brick_kiln_union" width="200" headerText='Brick Kiln Union' />
                <ColumnDirective field="trade_license_number" width="200" headerText='Tread License No.' />
                <ColumnDirective field="adm_license_no" width="200" headerText='ADM License No.' />
                <ColumnDirective field="turnover_figure" width="200" headerText='Turnover ' /> */}
                <ColumnDirective field="pending_status" width="200" headerText='Status ' template={StatusTemplate} />
                {/* turnover_figure */}
                <ColumnDirective field='' headerText='Action' width={120} template={EditHandler} />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Filter]} />

        </GridComponent>
    )
}

export default DataGrid