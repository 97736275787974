import React from 'react';
import './App.css';
import { user } from './Data/auth';
import Navigation from './Pages/Navigation';
import OtpModal from './components/OtpModal/OtpModal';
import { registerLicense } from "@syncfusion/ej2-base";
import { Toaster } from "react-hot-toast"


registerLicense(
  "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9TdURjUX5Yc3JWQmBZ"
);

function App() {
  console.log(user)



  return <div>
    <div><Toaster position="top-center"
      reverseOrder={false} /></div>
    {user ? <Navigation /> : <OtpModal />}
  </div>


}

export default App;


