import axios from "axios";


export const addNewSurvery = ({ key, formValues }) => {


    let data = new FormData();


    data.append('enterprise_name', formValues?.enterprise_name);
    data.append('enterprise_full_address', formValues?.enterprise_full_address);
    data.append('enterprise_district', formValues?.enterprise_district);
    data.append('enterprise_police_station', formValues?.enterprise_police_station);
    data.append('enterprise_pincode', formValues?.enterprise_pincode);
    data.append('owner_name', formValues?.owner_name);
    data.append('owner_mobile', formValues?.owner_mobile);
    data.append('brick_kiln_union', formValues?.brick_kiln_union);
    data.append('has_trade_license', formValues?.has_trade_license);
    data.append('trade_license_number', formValues?.trade_license_number);
    data.append('trade_license_photo', formValues?.trade_license_photo);
    data.append('brick_kiln_capacity', formValues?.brick_kiln_capacity);
    data.append('has_adm_permission', formValues?.has_adm_permission);
    data.append('adm_license_no', formValues?.adm_license_no);
    data.append('adm_license_photo', formValues?.adm_license_photo);
    data.append('is_orchards_nearby', formValues?.is_orchards_nearby);
    data.append('orchard_distance', formValues?.orchard_distance);
    data.append('is_zigzag_used', formValues?.is_zigzag_used);
    data.append('soil_source', formValues?.soil_source);
    data.append('soil_source_license_no', formValues?.soil_source_license_no);
    data.append('soil_source_license_photo', formValues?.soil_source_license_photo);
    data.append('fuel_type', formValues?.fuel_type);
    data.append('fuel_source', formValues?.fuel_source);
    data.append('is_wbpcb_cte_cto', formValues?.is_wbpcb_cte_cto);
    data.append('cte_no', formValues?.cte_no);
    data.append('cto_no', formValues?.cto_no);
    data.append('latitude', formValues?.latitude);
    data.append('longitude', formValues?.longitude);

    data.append('area', formValues?.area);
    data.append('employee_count', formValues?.employee_count);
    data.append('is_furnace_fixed', formValues?.is_furnace_fixed);
    data.append('establishment_date', formValues?.establishment_date);
    data.append('gstin_no', formValues?.gstin_no);
    data.append('gstin_photo', formValues?.gstin_photo);
    data.append('turnover_figure', formValues?.turnover_figure);
    data.append('zigzag_installation_date', formValues?.zigzag_installation_date);
    data.append('zigzag_supplier', formValues?.zigzag_supplier);
    data.append('is_soil_slit_others', formValues?.is_soil_slit_others);
    data.append('other_source', formValues?.other_source);
    data.append('slit_river_source', formValues?.slit_river_source);
    data.append('is_fly_ash_mixed', formValues?.is_fly_ash_mixed);
    data.append('fly_ash_source', formValues?.fly_ash_source);
    data.append('fly_ash_percent', formValues?.fly_ash_percent);
    data.append('male_worker_count', formValues?.male_worker_count);
    data.append('female_worker_count', formValues?.female_worker_count);
    data.append('other_worker_count', formValues?.other_worker_count);
    data.append('is_toilet_available', formValues?.is_toilet_available);
    data.append('is_toilet_separate', formValues?.is_toilet_separate);
    data.append('is_air_pollution_control_device_installed', formValues?.is_air_pollution_control_device_installed);
    data.append('air_pollution_control_device_details', formValues?.air_pollution_control_device_details);
    data.append('is_child_labour_used', formValues?.is_child_labour_used);


    formValues?.enterprise_photo?.map((item) => data.append('enterprise_photo', item))

    var requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${key}`,
        },
        body: data,
        redirect: 'follow'
    };

    const res = fetch('https://brick-klin.wbpcb.gov.in/api/form/', requestOptions)
        .then(response => response.json())
        .then(result => { return (result) })
        .catch(error => console.log('error', error));


    return res;

}

export const updateSurvery = ({ key, formValues }) => {




    let data = new FormData();




    data.append('enterprise_name', formValues?.enterprise_name);
    data.append('enterprise_full_address', formValues?.enterprise_full_address);
    data.append('enterprise_district', formValues?.enterprise_district);
    data.append('enterprise_police_station', formValues?.enterprise_police_station);
    data.append('enterprise_pincode', formValues?.enterprise_pincode);
    data.append('owner_name', formValues?.owner_name);
    data.append('owner_mobile', formValues?.owner_mobile);
    data.append('brick_kiln_union', formValues?.brick_kiln_union);
    data.append('has_trade_license', formValues?.has_trade_license);
    data.append('trade_license_number', formValues?.trade_license_number);
    data.append('trade_license_photo', formValues?.trade_license_photo);
    data.append('brick_kiln_capacity', formValues?.brick_kiln_capacity);
    data.append('has_adm_permission', formValues?.has_adm_permission);
    data.append('adm_license_no', formValues?.adm_license_no);
    data.append('adm_license_photo', formValues?.adm_license_photo);
    data.append('is_orchards_nearby', formValues?.is_orchards_nearby);
    data.append('orchard_distance', formValues?.orchard_distance);
    data.append('is_zigzag_used', formValues?.is_zigzag_used);
    data.append('soil_source', formValues?.soil_source);
    data.append('soil_source_license_no', formValues?.soil_source_license_no);
    data.append('soil_source_license_photo', formValues?.soil_source_license_photo);
    data.append('fuel_type', formValues?.fuel_type);
    data.append('fuel_source', formValues?.fuel_source);
    data.append('is_wbpcb_cte_cto', formValues?.is_wbpcb_cte_cto);
    data.append('cte_no', formValues?.cte_no);
    data.append('cto_no', formValues?.cto_no);
    data.append('latitude', formValues?.latitude);
    data.append('longitude', formValues?.longitude);
    formValues?.enterprise_photo?.map((item) => data.append('enterprise_photo', item))
    data.append('area', formValues?.area);
    data.append('employee_count', formValues?.employee_count);
    data.append('is_furnace_fixed', formValues?.is_furnace_fixed);
    data.append('establishment_date', formValues?.establishment_date);
    data.append('gstin_no', formValues?.gstin_no);
    data.append('gstin_photo', formValues?.gstin_photo);
    data.append('turnover_figure', formValues?.turnover_figure);
    data.append('zigzag_installation_date', formValues?.zigzag_installation_date);
    data.append('zigzag_supplier', formValues?.zigzag_supplier);
    data.append('is_soil_slit_others', formValues?.is_soil_slit_others);
    data.append('other_source', formValues?.other_source);
    data.append('slit_river_source', formValues?.slit_river_source);
    data.append('is_fly_ash_mixed', formValues?.is_fly_ash_mixed);
    data.append('fly_ash_source', formValues?.fly_ash_source);
    data.append('fly_ash_percent', formValues?.fly_ash_percent);
    data.append('male_worker_count', formValues?.male_worker_count);
    data.append('female_worker_count', formValues?.female_worker_count);
    data.append('other_worker_count', formValues?.other_worker_count);
    data.append('is_toilet_available', formValues?.is_toilet_available);
    data.append('is_toilet_separate', formValues?.is_toilet_separate);
    data.append('is_air_pollution_control_device_installed', formValues?.is_air_pollution_control_device_installed);
    data.append('air_pollution_control_device_details', formValues?.air_pollution_control_device_details);
    data.append('is_child_labour_used', formValues?.is_child_labour_used);



    // let config = {
    //     method: 'put',
    //     maxBodyLength: Infinity,
    //     url: `https://brick-klin.wbpcb.gov.in/api/form/?form_id=${formValues?.id}`,
    //     headers: {
    //         'Authorization': `Bearer ${key}`,
    //     },
    //     data: formValues
    // };

    // const res = axios.request(config)
    //     .then((response) => {
    //         return response.data;
    //     })
    //     .catch((error) => {
    //         return error;
    //     });

    // return res;

    var requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${key}`,
        },
        body: data,
        redirect: 'follow'
    };

    const res = fetch(`https://brick-klin.wbpcb.gov.in/api/form/?form_id=${formValues?.id}`, requestOptions)
        .then(response => response.json())
        .then(result => { return (result) })
        .catch(error => console.log('error', error));


    return res;

}