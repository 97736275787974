import React, { useState, useEffect, useRef } from 'react'
import ArrowRight from "../../assets/arrowRight.png"
import CloseIcon from "../../assets/Close.svg"
import { useLocation, useNavigate } from "react-router-dom";
import "./Style.css"
import Page1 from './Page1'
import { Formik } from 'formik';
import ProgressBar from '../ProgressBar'
import Page2 from './Page2'
import Page3 from './Page3'
import * as yup from "yup";
import { Toaster, toast } from 'react-hot-toast';
import { addNewSurvery, updateSurvery } from '../../apis/createForm';
import PreviewModal from './Preview';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

const BrickKilnFrom = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate();

  const params = useLocation();
  const args = params.state;

  const EditValue = JSON.parse(args);




  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // Handle permission denied
            console.error("Location access denied by the user.");
          } else {
            console.error("Error getting location:", error);
          }
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);


  const _oSubmitHandler = async (values) => {

    const key = JSON.parse(localStorage.getItem('user'))

    setIsloading(true)
    const res = EditValue ? await updateSurvery({ key: key, formValues: { ...values, ...location } }) : await addNewSurvery({ key: key, formValues: { ...values, ...location } });

    if (res?.status === "Success") {
      toast.success(res?.message)
      setTimeout(() => navigate("/"))
      setIsloading(false)

    } else {
      setIsloading(false)
      toast.error(res?.message || "Something went wrong , Please Relogin and try again !!")
    }
  }


  const initValue = {
    enterprise_full_address: "",
    enterprise_district: "",
    enterprise_police_station: "",
    enterprise_pincode: "",
    owner_name: "",
    owner_mobile: "",
    brick_kiln_union: "",
    has_trade_license: "",
    trade_license_number: "",
    has_adm_permission: "",
    adm_license_no: "",
    is_orchards_nearby: "",
    orchard_distance: "",
    is_zigzag_used: "",
    soil_source: "",
    soil_source_license_no: "",
    fuel_type: "",
    fuel_source: "",
    is_wbpcb_cte_cto: "",
    cte_no: "",
    cto_no: "",
    latitude: "",
    longitude: "",
    area: "",
    employee_count: "",
    trade_license_photo_path: "",
    adm_license_photo_path: "",
    soil_source_license_photo_path: "",
    enterprise_photo_path: "",
    user_id: "",
    user_phone: "",
    enterprise_name: "",
    brick_kiln_capacity: "",
    userId: "",
    gstin_no: "",
    establishment_date: "",
    turnover_figure: "",
    is_fly_ash_mixed: "",
    other_source: "",
    trade_license_photo: "",
    gstin_photo: "",
    is_soil_slit_others: "",
    is_toilet_available: "",
    is_toilet_separate: "",
    is_air_pollution_control_device_installed: '',
    is_child_labour_used: "",
    is_furnace_fixed: "",
    enterprise_photo: '',
    zigzag_installation_date: ""

  }
  const survreyFormValidation = yup.object().shape({
    enterprise_name: yup
      .string()
      .required("Enterprise Name is required")
      .min(2, "Enterprise Name must be at least 2 characters")
      .max(50, "Enterprise Name cannot exceed 50 characters"),
    enterprise_full_address: yup
      .string()
      .required("Enterprise Full Address is required")
      .min(2, "Enterprise Full Address must be at least 2 characters")
      .max(150, "Enterprise Full Address cannot exceed 50 characters"),
    enterprise_district: yup
      .string()
      .required("Enterprise District is required")
      .min(2, "Enterprise District must select from dropdown"),
    enterprise_police_station: yup
      .string()
      .required("Enterprise PS is required")
      .min(2, "Enterprise PS must select from dropdown"),

    establishment_date: yup
      .string()
      .required("Enterprise Date is required"),

    enterprise_pincode: yup
      .string()
      .matches(
        /^[0-9]{6}$/, // You can adjust the regular expression based on your mobile number format.
        "Invalid pin code. It should be a 6-digit number."
      )
      .required("Mobile number is required"),

    owner_name: yup
      .string()
      .required("Owner Name is required")
      .min(2, "Owner Name must be at least 2 characters")
      .max(50, "Owner Name cannot exceed 50 characters"),
    owner_mobile: yup
      .string()
      .matches(
        /^[0-9]{10}$/, // You can adjust the regular expression based on your mobile number format.
        "Invalid mobile number. It should be a 10-digit number."
      )
      .required("Mobile number is required"),
    // has_trade_license: yup
    //   .string()
    //   .required(),

    // is_wbpcb_cte_cto: yup
    //   .string()
    //   .required(),
    // has_adm_permission: yup
    //   .string()
    //   .required(),
    // is_orchards_nearby: yup
    //   .string()
    //   .required(),
    // is_zigzag_used: yup
    //   .string()
    //   .required(),
    // is_fly_ash_mixed: yup
    //   .string()
    //   .required(),
    // trade_license_number: yup
    //   .string()
    //   .required("Trade License is required")
    //   .min(2, "Trade License must be at least 2 characters")
    //   .max(50, "Trade License cannot exceed 50 characters"),
    // trade_license_photo: yup
    //   .string()
    //   .required("Trade License Photo is required"),

    // gstin_no: yup
    //   .string()
    //   .required("GSTIN NO is required")
    //   .min(2, "GSTIN NO must be at least 2 characters")
    //   .max(50, "GSTIN NO cannot exceed 50 characters"),
    // gstin_photo: yup
    //   .string()
    //   .required("GSTIN Photo is required"),
    // turnover_figure: yup.string()
    //   .required("Turnover Figure is required"),

    // turnover_figure: yup.string()
    //   .required("Turnover Figure is required"),
    // brick_kiln_union: yup.string()
    //   .required("Brick Kiln Union is required"),
    brick_kiln_capacity: yup.string()
      .required("Brick Kiln Capacity is required"),
    // soil_source_license_no: yup.string()
    //   .required("Licence is required"),

    // is_soil_slit_others: yup.string()
    //   .required("Licence is required"),
    // soil_source_license_photo: yup.string()
    //   .required("Required"),
    fuel_type: yup.string()
      .required("Required"),
    fuel_source: yup.string()
      .required("Required"),
    employee_count: yup.string()
      .required("Required"),
    male_worker_count: yup.string()
      .required("Required"),
    female_worker_count: yup.string()
      .required("Required"),
    other_worker_count: yup.string()
      .required("Required"),
    is_toilet_available: yup.string()
      .required("Required"),
    is_toilet_separate: yup.string()
      .required("Required"),
    is_air_pollution_control_device_installed: yup.string()
      .required("Required"),
    is_child_labour_used: yup.string()
      .required("Required"),
    is_furnace_fixed: yup.string()
      .required("Required"),
    // area: yup.string()
    //   .required("Required"),
    enterprise_photo: yup.array()
      .required("Required"),

  });

  const formikRef = useRef(null);

  return (
    <div className='px-6 py-4 bg-[#d9eef1] h-fit'>
      {/* Page 1 */}

      <div className=" bg-[#d9eef1]">

        <><h1 className='text-xl font-medium text-center'>WBPCB BRICK KILN SURVEY FORM</h1>
          <ProgressBar percent={(currentPage / 3) * 100} />
        </>

        <Formik initialValues={EditValue ? EditValue : initValue}
          validationSchema={!EditValue && survreyFormValidation}
          onSubmit={(values) => !isLoading && _oSubmitHandler(values)}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setValues
            /* and other goodies */
          }) => <form >{currentPage === 1 ?
            // Question 1- 8
            <Page1 errors={errors} touched={touched} handleChange={handleChange} values={values} setValues={setValues} /> :
            currentPage === 2 ?
              // Question 1- 8
              <Page2 errors={errors} touched={touched} handleChange={handleChange} values={values} setValues={setValues} /> :
              // Question 1- 8
              <Page3 errors={errors} touched={touched} handleChange={handleChange} values={values} setValues={setValues} location={location} />}
              {/* PreviewModal */}
              <PreviewModal handleClose={() => setIsOpen(false)} open={isOpen} children={
                <div >
                  <h1 className='text-center -mt-5 text-xl font-bold'>Preview</h1>
                  <Page1 errors={errors} touched={touched} isDisable={true} handleChange={() => { }} values={values} setValues={setValues} />
                  <Page2 errors={errors} touched={touched} isDisable={true} handleChange={() => { }} values={values} setValues={setValues} />
                  <Page3 errors={errors} touched={touched} isDisable={true} handleChange={() => { }} values={values} setValues={setValues} location={location} />
                  {/* formikRef.current.submitForm() */}
                  <div className='flex self-center justify-center bg-gray-400 mt-2 py-2 px-2 rounded-lg select-none items-center' onClick={() => { setIsOpen(false) }}>
                    <h1 className='text-white text-base select-none font-semibold mr-2'>Cancel</h1>
                  </div>
                  <div className='flex self-center justify-center bg-green-800 mt-2 py-2 px-2 rounded-lg select-none items-center' onClick={() => { formikRef.current.submitForm() }}>
                    <h1 className='text-white text-base select-none font-semibold mr-2'>Submit</h1> {isLoading && <Spinner color='white' />}
                  </div>
                </div>
              } />

              <div className=' flex items-center justify-end mt-4 space-x-2'>

                <div className='flex  bg-white py-2 px-2 rounded-lg select-none items-center' onClick={() => {
                  navigate("/")
                }}>
                  <img src={CloseIcon} alt="ArrowRight" className="h-5 w-5 select-none" />
                  <h1 className=' text-base select-none font-semibold mr-2'>cancel</h1>

                </div>
                {currentPage < 2 || currentPage < 4 && <div className='flex space-x-1 items-center bg-white py-2 px-2 rounded-lg select-none' onClick={() => {
                  setCurrentPage(currentPage - 1)
                }}>
                  <img src={ArrowRight} alt="ArrowRight " className="h-6 w-6 select-none rotate-180" />
                  <h1 className=' text-base select-none font-semibold mr-2'>Previous</h1>
                </div>}


                <div className='flex  bg-white py-2 px-2 rounded-lg select-none items-center' onClick={() => {
                  currentPage < 3 && setCurrentPage(currentPage + 1)
                  // !isLoading && _oSubmitHandler(values)
                  if (currentPage === 3 && formikRef.current) {
                    // formikRef.current.submitForm();
                    setIsOpen(true)
                  }
                }}>
                  <h1 className=' text-base select-none font-semibold mr-2'>{currentPage === 3 ? "Preview" : "Next"}</h1>
                  {currentPage < 3 && <img src={ArrowRight} alt="ArrowRight" className="h-4 w-4 select-none" />}
                </div>

              </div>

            </form>}

        </Formik>



      </div>
    </div >
  )
}

export default BrickKilnFrom


