import React, { useEffect, useRef, useState } from "react";
import AutoComplete from "../AutoComplete";
import UploadIcon from "../../assets/UploadIcon.png";
import { Field } from "formik"
import BasicDatePicker from "../DatePicker";
import moment from "moment";


const Page1 = ({ values, setValues, handleChange, errors, touched, isDisable }) => {
  const [selectedTreadLicence, setSelectedTreadLicence] = useState(values?.trade_license_photo);
  const [selectedGSTINFile, setSelectedGSTINFile] = useState(values?.gstin_photo_path)
  const [districtList, setDistrictList] = useState([]);
  const [districtPSList, setDistrictPSList] = useState([]);
  const [selectedPS, setSelectedPS] = useState(
    values?.enterprise_police_station || "Select PS"
  );

  const [haveTreadLicence, setHaveTreadLicence] = useState(values?.has_trade_license);

  const accessToken = localStorage.getItem("accessToken");

  const ReadDistrictList = () => {
    const res = fetch("https://brick-klin.wbpcb.gov.in/api/form/district", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) =>
        setDistrictList(
          result?.data?.districts.map((item) => ({ label: item }))
        )
      )
      .catch((error) => console.log("error", error));
  };

  const ReadDistricPStList = ({ psName }) => {
    const res = fetch(
      `https://brick-klin.wbpcb.gov.in/api/form/police_station?district_name=${psName}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) =>
        setDistrictPSList(
          result?.data?.police_station.map((item) => ({ label: item }))
        )
      )
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    ReadDistrictList();
  }, []);

  const fileInputRef = useRef(null);
  const handleImageChange = (event) => {
    const file = event.target.files?.[0];
    setSelectedTreadLicence(file);
    setValues({ ...values, trade_license_photo: file })
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef?.current?.click();
    }
  };

  const fileGSTINRef = useRef(null);
  const handleGSTINImageChange = (event) => {
    const file = event.target.files?.[0];
    setSelectedGSTINFile(file);
    setValues({ ...values, gstin_photo: file })
  };

  const handleButtonGSTINClick = () => {
    if (fileGSTINRef.current) {
      fileGSTINRef?.current?.click();
    }
  };
  return (
    <div>
      {/* Question 1 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">1. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Name of Enterprise ?<span className="text-red-600">*</span>
          </h1>
        </div>
        <input
          name={"enterprise_name"}
          value={values?.enterprise_name}
          onChange={handleChange}
          className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.enterprise_name && " border-[2px] border-red-600"}`}
          placeholder="Name of Enterprise"
          disabled={isDisable}
        />
        {errors.enterprise_name && <div className=" text-red-600">
          {errors.enterprise_name}</div>}
      </div>


      {/* Question 2 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">2. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Address of Enterprise ?<span className="text-red-600">*</span>
          </h1>
        </div>
        <input
          disabled={isDisable}
          name={"enterprise_full_address"}
          value={values?.enterprise_full_address}
          onChange={handleChange}
          placeholder="Address"
          className={` bg-white  w-full rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  mb-2 ${errors.enterprise_full_address && " border-[2px] mb-0 border-red-600"}`}
        />
        {errors.enterprise_full_address && <div className=" text-red-600 mb-2">
          {errors.enterprise_full_address}</div>}
        <div className="mb-2">
          <AutoComplete
            isDisable={isDisable}
            data={districtList}
            onClick={(e) => {
              setValues({
                ...values,
                enterprise_district: e?.label,
                enterprise_police_station: "",
              });
              ReadDistricPStList({ psName: e?.label });
              setSelectedPS("");
            }}
            isError={errors.enterprise_district}
            preValue={values?.enterprise_district || "Select District "}
          />
          {errors.enterprise_district && <div className="mb-2 text-red-600">
            {errors.enterprise_district}</div>}
        </div>
        <div className="mb-2">
          <AutoComplete
            data={districtPSList}
            onClick={(e) => {
              setValues({ ...values, enterprise_police_station: e?.label });
              setSelectedPS(e?.label);
            }}
            preValue={selectedPS}
            isError={errors?.enterprise_police_station}
            isDisable={isDisable}
          />
        </div>
        {errors.enterprise_police_station && <div className="mb-2 text-red-600">
          {errors.enterprise_police_station}</div>}
        <input
          disabled={isDisable}
          name="enterprise_pincode"
          value={values?.enterprise_pincode}
          onChange={handleChange}
          type="number"
          placeholder="Pincode"
          className={`bg-white  w-full rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  mb-2 ${errors.enterprise_pincode && "border-red-600 mb-0"}`}
        />
        {errors.enterprise_pincode && <div className="mb-2 text-red-600">
          {errors.enterprise_pincode}</div>}
      </div>

      {/* Question 3 */}

      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">3. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Date of establishment?<span className="text-red-600">*</span>
          </h1>
        </div>
        <BasicDatePicker isDisable={isDisable} preValue={values?.establishment_date} onChange={(e) => setValues({ ...values, establishment_date: moment(({ ...e }.$d)).format("YYYY-MM-DD") })} />
        {errors?.establishment_date && <div className="mb-2 text-red-600">
          {errors.establishment_date}</div>}
      </div>

      {/* Question 4 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">4. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Name of Owner?<span className="text-red-600">*</span>
          </h1>
        </div>
        <input
          disabled={isDisable}
          name="owner_name"
          value={values?.owner_name}
          onChange={handleChange}
          className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.owner_name && " border-[2px] border-red-600"}`}
          placeholder="Name of Owner"
        />
        {errors?.owner_name && <div className=" text-red-600">
          {errors.owner_name}</div>}
      </div>

      {/* Question 5 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">5. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Mobile Number of Owner?<span className="text-red-600">*</span>
          </h1>
        </div>
        <input
          disabled={isDisable}
          name="owner_mobile"
          value={values?.owner_mobile}
          type="number"
          onChange={handleChange}
          max={10}
          className={`bg-white  rounded-md h-12 border-[2px] ${errors.owner_mobile && "border-red-600"} border-[#d5d5d5] outline-none px-2  w-full`}
          placeholder="Mobile Number"
        />
        {errors.owner_mobile && (
          <div className="text-red-600">{errors.owner_mobile}</div>
        )}
      </div>

      {/* Question 6 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">6. </h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Trade licence (yes/no)?
          </h1>
        </div>
        <div className="mb-2">
          <AutoComplete
            isDisable={isDisable}
            data={[{ label: "Yes" }, { label: "No" }]}
            onClick={(e) => { setHaveTreadLicence(e?.label); setValues({ ...values, has_trade_license: e?.label }) }}
            preValue={values?.has_trade_license}
            isError={errors?.has_trade_license}
          />
        </div>

        {haveTreadLicence === "Yes" && (
          <>
            <div className="py-1">
              <div className=" flex">
                <h1 className="py-[2px] text-lg mr-1">*</h1>
                <h1 className="py-1 text-[15px] text-clip font-semibold">
                  Enter Trade Licence number
                </h1>
              </div>
              <input
                disabled={isDisable}
                name="trade_license_number"
                value={values?.trade_license_number}
                onChange={handleChange}
                className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.trade_license_number && " border-[2px] border-red-600"}`}
                placeholder="Licence number"
              />
              {errors?.trade_license_number && <div className=" mb-2 text-red-600">
                {errors?.trade_license_number}</div>}

              {values?.trade_license_photo ? (
                <img
                  src={URL.createObjectURL(values?.trade_license_photo)}
                  alt="Selected"
                  style={{ maxWidth: '300px' }}
                />
              ) : values?.trade_license_photo_path ? <img src={`https://brick-klin.wbpcb.gov.in/api/static?path=${values?.trade_license_photo_path}`} className="pt-2 w-40 h-40" /> : ""}
              <input
                disabled={isDisable}
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className="mx-4 px-4 text-md text-white"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <div className=" flex">
                <h1 className="py-[2px] text-lg mr-1">*</h1>
                <h1 className="py-1 text-[15px] text-clip font-semibold">
                  Upload Trade Licence Photo
                </h1>
              </div>
              <div
                className={` border-[2px] ${errors?.trade_license_photo ? "border-red-600" : "border-[#d5d5d5]"}  h-12 bg-white rounded-lg  flex  justify-between items-center px-4 text-[#4c9daf]`}
                onClick={handleButtonClick}
              >
                {selectedTreadLicence?.name
                  ? selectedTreadLicence?.name
                  : "Upload file"}
                <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
              </div>
              {errors.trade_license_photo && (
                <div className="text-red-600">{errors.trade_license_photo}</div>
              )}
            </div>
          </>
        )}
      </div>



      {/* Question 7 */}
      <div className="py-1">
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">7.</h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Enter GSTIN No.
          </h1>
        </div>
        <input
          disabled={isDisable}
          name="gstin_no"
          value={values?.gstin_no}
          onChange={handleChange}
          className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.gstin_no && " border-[2px] border-red-600"}`}
          placeholder="Licence number"
        />
        {errors.gstin_no && (
          <div className="text-red-600">{errors.gstin_no}</div>
        )}

        {values?.gstin_photo ? (
          <img
            src={URL.createObjectURL(values?.gstin_photo)}
            alt="Selected"
            style={{ maxWidth: '300px' }}
          />
        ) : values?.gstin_photo_path && <img src={`https://brick-klin.wbpcb.gov.in/api/static?path=${values?.gstin_photo_path}`} className="pt-2 w-40 h-40" />}
        <input
          disabled={isDisable}
          type="file"
          name="image"
          accept="image/*"
          onChange={handleGSTINImageChange}
          className="mx-4 px-4 text-md text-white "
          style={{ display: "none" }}
          ref={fileGSTINRef}
        />
        <div className=" flex">
          <h1 className="py-[2px] text-lg mr-1">*</h1>
          <h1 className="py-1 text-[15px] text-clip font-semibold">
            Upload GSTIN No. Photo
          </h1>
        </div>
        <div
          className={` border-[2px] ${errors?.gstin_photo ? "border-red-600" : "border-[#d5d5d5]"}  h-12 bg-white rounded-lg  flex  justify-between items-center px-4 text-[#4c9daf]`}
          onClick={handleButtonGSTINClick}
        >
          {selectedGSTINFile?.name
            ? selectedGSTINFile?.name
            : "Upload file"}
          <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
        </div>
      </div>

      {
        errors.gstin_photo_path && (
          <div className="text-red-600">{errors.gstin_photo_path}</div>
        )
      }
    </div >
  );
};

export default Page1;
