import React, { useRef, useState } from 'react'
import AutoComplete from '../AutoComplete'
import UploadIcon from "../../assets/UploadIcon.png"
import moment from "moment";
import BasicDatePicker from '../DatePicker';

const Page2 = ({ values, setValues, handleChange, errors, touched, isDisable }) => {
    const [selectedTreadLicence, setSelectedTreadLicence] = useState(values?.adm_license_photo);
    const [selectedSourceLicence, setSelectedSourceLicence] = useState(values?.soil_source_license_photo);
    const [haveTreadLicence, setHaveTreadLicence] = useState(values?.is_wbpcb_cte_cto);


    const [hasADMPermissionLicence, setHasADMPermissionLicence] = useState(values?.has_adm_permission);
    const [nearByOrchard, setNearByOrchard] = useState(values?.is_orchards_nearby);

    const [isZigZagTechUsed, setIsZigZagTechUsed] = useState(values?.is_zigzag_used);



    const fileInputRef = useRef(null);
    const fileInputSourceRef = useRef(null);
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files?.[0];
        setSelectedTreadLicence(file);
        setValues({ ...values, adm_license_photo: file })
    };
    const handleButtonSourceClick = () => {
        if (fileInputSourceRef.current) {
            fileInputSourceRef.current.click();
        }
    };
    const handleImageSourceChange = (event) => {
        const file = event.target.files?.[0];
        setSelectedSourceLicence(file);
        setValues({ ...values, soil_source_license_photo: file })
    };


    return (
        <div>
            {/* Question 8 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>8. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>WBPCB CTE / CTO?</h1>
                </div>
                <div className='mb-2'>
                    <AutoComplete isDisable={isDisable} isError={errors?.is_wbpcb_cte_cto} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setHaveTreadLicence(e?.label); setValues({ ...values, is_wbpcb_cte_cto: e?.label }) }} preValue={values?.is_wbpcb_cte_cto} />

                </div>

                {haveTreadLicence === "Yes" && <>
                    <div className='py-1'>
                        <input disabled={isDisable} name='cte_no' value={values?.cte_no} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2 mb-2 w-full' placeholder='CTE No.' />
                        <input disabled={isDisable} name='cto_no' value={values?.cto_no} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='CTO No.' />

                    </div>
                </>}
            </div>
            {/* Question 9 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>9. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'> Turnover figure in lakhs for last
                        3 years? (Approx.)</h1>
                </div>
                <input disabled={isDisable} name='turnover_figure' value={values?.turnover_figure} onChange={handleChange} className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5]  outline-none px-2  w-full ${errors?.turnover_figure && "border-red-600"} `} placeholder='10' type='number' />

                {errors.turnover_figure && (
                    <div className="text-red-600">{errors.turnover_figure}</div>
                )}
            </div>
            {/* Question 10*/}
            <div className="py-1">
                <div className=" flex">
                    <h1 className="py-[2px] text-lg mr-1">10. </h1>
                    <h1 className="py-1 text-[15px] text-clip font-semibold">
                        Part of Which Brick Kiln Union?
                    </h1>
                </div>
                <input disabled={isDisable} name='brick_kiln_union' value={values?.brick_kiln_union} onChange={handleChange} className={` bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors?.brick_kiln_union && "border-red-600"} `} placeholder='' />

                {errors.brick_kiln_union && (
                    <div className="text-red-600">{errors.brick_kiln_union}</div>
                )}

                {/* <div className="mb-2">
                    <AutoComplete
                        data={[]}brick_kiln_union
                        onClick={() => { console.log({ brick_kiln_union: "" }) }}
                        preValue={"Select Brick Kiln Union "}
                    />
                </div> */}
            </div>
            {/* Question 11 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>11. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>The capacity of Brick Kiln? ?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='brick_kiln_capacity' value={values?.brick_kiln_capacity} onChange={handleChange} className={` bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors?.brick_kiln_capacity && "border-red-600"} `} placeholder='10K Bricks per Day, 20K Bricks per Day, etc' />

                {errors.brick_kiln_capacity && (
                    <div className="text-red-600">{errors.brick_kiln_capacity}</div>
                )}
            </div>

            {/* Question 12 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>12. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>ADM PERMISSION Permission/licence ?</h1>
                </div>
                <div className='mb-2'>
                    <AutoComplete isDisable={isDisable} title={"dsfdf"} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => {
                        setHasADMPermissionLicence(e?.label);
                        setValues({ ...values, has_adm_permission: e?.label })
                    }} preValue={values?.has_adm_permission} isError={errors?.has_adm_permission} />

                </div>

                {hasADMPermissionLicence === "Yes" && <>
                    <div className='py-1'>
                        <div className=' flex'>
                            <h1 className='py-[2px] text-lg mr-1'>*</h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Enter Permission/licence number</h1>
                        </div>
                        <input disabled={isDisable} name='adm_license_no' value={values?.adm_license_no} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='Permission/licence number' />
                        {values?.adm_license_photo ? (
                            <img
                                src={URL.createObjectURL(values?.adm_license_photo)}
                                alt="Selected"
                                style={{ maxWidth: '300px' }}
                            />
                        ) : values?.adm_license_photo_path && <img src={`https://brick-klin.wbpcb.gov.in/api/static?path=${values?.adm_license_photo_path}`} className="pt-2 w-40 h-40" />}

                        <input
                            disabled={isDisable}
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="mx-4 px-4 text-md text-white "
                            style={{ display: "none" }}
                            ref={fileInputRef}
                        />
                        <div className=' flex'>
                            <h1 className='py-[2px] text-lg mr-1'>*</h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Upload Permission/licence Photo</h1>
                        </div>
                        <div className=" border-[2px] border-[#d5d5d5] h-12 bg-white rounded-lg  flex  justify-between items-center px-4 text-[#4c9daf]" onClick={handleButtonClick}>
                            {selectedTreadLicence?.name ? selectedTreadLicence?.name : "Upload file"}
                            <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
                        </div>
                    </div>
                </>}
            </div>
            {/* Question 13 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>13. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Mango Orchards nearby??</h1>
                </div>
                <div className='mb-2'>
                    <AutoComplete isDisable={isDisable} isError={errors?.is_orchards_nearby} title={"dsfdf"} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setNearByOrchard(e?.label); setValues({ ...values, is_orchards_nearby: e?.label }) }} preValue={values?.is_orchards_nearby} />

                </div>
                {nearByOrchard === "Yes" && <>
                    <div className=' flex'>
                        <h1 className='py-[2px] text-lg mr-1'>*</h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Distance from nearest Orchard?</h1>
                    </div>
                    <input disabled={isDisable} name='orchard_distance' value={values?.orchard_distance} onChange={handleChange} placeholder='Distance in KM' className=' bg-white  w-full rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  mb-2' /></>}
            </div>

            {/* Question 14 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>14. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Is Zig-zag technology being used?</h1>
                </div>
                <div className='mb-2'>
                    <AutoComplete isDisable={isDisable} isError={errors?.is_zigzag_used} title={"dsfdf"} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setIsZigZagTechUsed(e?.label); setValues({ ...values, is_zigzag_used: e?.label }) }} preValue={values?.is_zigzag_used} />

                </div>

                {isZigZagTechUsed === "Yes" && <div className='py-1'>
                    <div className=' flex'>
                        <h1 className='py-[2px] text-lg mr-1'>15. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Date of Installation &amp; name of the supplier of Zig-zag Technology?</h1>
                    </div>
                    <BasicDatePicker isDisable={isDisable} preValue={values?.zigzag_installation_date} onChange={(e) => setValues({ ...values, zigzag_installation_date: moment(({ ...e }.$d)).format("YYYY-MM-DD") })} />
                    <input disabled={isDisable} name='zigzag_supplier' value={values?.zigzag_supplier} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full my-2' placeholder='Name of the supplier of Zig-zag Technology' />

                </div>}

            </div>




            {/* Question 16 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>16. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Source of Agricultural Soil or Silt or others?</h1>
                </div>
                <AutoComplete isDisable={isDisable} data={[{ label: "Soil" }, { label: "Silt" }, { label: "Others" }]} preValue={values?.is_soil_slit_others} onClick={(e) => { setValues({ ...values, is_soil_slit_others: e?.label }) }} isError={errors?.is_soil_slit_others} />
                {errors.is_soil_slit_others && (
                    <div className="text-red-600">{errors.is_soil_slit_others}</div>
                )}
                {
                    values?.is_soil_slit_others === 'Silt' ?
                        <div className='py-1'>
                            <input disabled={isDisable} name='slit_river_source' value={values?.slit_river_source} onChange={handleChange} className=' bg-white mb-2 rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='River name' />
                        </div> :
                        values?.is_soil_slit_others === 'Other' &&
                        <div className='py-1'>
                            <input disabled={isDisable} name='other_source' value={values?.other_source} onChange={handleChange} className=' bg-white mb-2 rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />
                        </div>
                }

            </div>
            {/* Question 17 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>17. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Licence No. of Source of Soil ? (ADM permission required)</h1>
                </div>
                <input disabled={isDisable} name='soil_source_license_no' value={values?.soil_source_license_no} onChange={handleChange} className={` bg-white mb-2 rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors?.soil_source_license_no && " border-red-600"}`} placeholder='Permission/licence number' />
                {errors.soil_source_license_no && (
                    <div className="text-red-600">{errors.soil_source_license_no}</div>
                )}

                {values?.soil_source_license_photo ? (
                    <img
                        src={URL.createObjectURL(values?.soil_source_license_photo)}
                        alt="Selected"
                        style={{ maxWidth: '300px' }}
                    />
                ) : values?.soil_source_license_photo_path && <img src={`https://brick-klin.wbpcb.gov.in/api/static?path=${values?.soil_source_license_photo_path}`} className="pt-2 w-40 h-40" />}
                <input
                    disabled={isDisable}
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageSourceChange}
                    className="mx-4 px-4 text-md text-white "
                    style={{ display: "none" }}
                    ref={fileInputSourceRef}
                />

                <div className={` border-[2px] border-[#d5d5d5] h-12 bg-white rounded-lg  flex  justify-between items-center px-4 text-[#4c9daf] ${errors?.soil_source_license_photo && "border-red-600"}`} onClick={handleButtonSourceClick}>
                    {selectedSourceLicence?.name ? selectedSourceLicence?.name : "Upload file"}
                    <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
                </div>
            </div>



        </div >
    )
}

export default Page2