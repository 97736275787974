import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import "./Style.css"
import moment from 'moment';

export default function BasicDatePicker({ onChange, preValue, isDisable }) {



    const [selectedDate, setSelectedDate] = React.useState();
    const onChangeX = (newDate) => {
        setSelectedDate(newDate);
        onChange(newDate)

    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <DemoContainer components={['DatePicker']} >
                <DatePicker disabled={isDisable} defaultValue={dayjs(dayjs(preValue))} format='DD/MM/YYYY' onChange={onChangeX} sx={{ bgcolor: "#ffffff", }} label="" InputLabelProps={{
                    shrink: false, // Hide the label
                }} />
            </DemoContainer>
        </LocalizationProvider>
    );
}