import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { wbpcb } from "../assets";
import DataGrid from "../components/DataGrid/DataGrid";
import Logout from "../assets/Logout.svg"
export default function IndexPage() {
  const navigate = useNavigate();







  const enterFullscreen = () => {
    const documentElement = document.documentElement;

    if (documentElement.requestFullscreen) {
      documentElement.requestFullscreen();
    } else if (documentElement.mozRequestFullScreen) { // Firefox
      documentElement.mozRequestFullScreen();
    } else if (documentElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      documentElement.webkitRequestFullscreen();
    } else if (documentElement.msRequestFullscreen) { // IE/Edge
      documentElement.msRequestFullscreen();
    }

  };

  return (
    <div className=" w-screen overflow-hidden">
   
      <div className=" float-right  " onClick={() => {

        localStorage.removeItem('user');
        window.location.reload()

      }}>
        <img src={Logout} className="w-10 h-10" />
      </div>
      <div className="flex justify-center items-center text-center flex-col py-10">


        <div className="px-12">
          <img src={wbpcb} alt="" className="w-fit" />
        </div>
        <div className="text-lg pt-8 font-bold uppercase">
          WBPCB BRICK KILN SURVEY FORM

        </div>
      </div>
      <div className="flex gap-3 mt-8 justify-center p-5  items-center">
        <span
          className="border p-2 px-4 font-semibold rounded-lg active:bg-teal-200"
          onClick={() => { navigate("/addNew"); }} //enterFullscreen();
        >
          Add New Unit
        </span>
      </div>

      {/* Data Grid for Edit Response */}
      <div className=" flex items-center justify-center ">
        <DataGrid />
      </div>


    </div>
  );
}
