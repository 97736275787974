/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Loader from "../Loader";
import { wbpcb } from "../../assets";
import Login from "../Auth/Login";
import { customRequest } from "../../Data/auth";
import 'rsuite/dist/rsuite-no-reset.min.css';
import toast, { Toaster } from 'react-hot-toast';



const OtpModal = () => {

  const [otp, setOtp] = useState("");
  const [waitTime, setWaitTime] = useState(0);
  const [click, setClick] = useState(false);
  const [verified, setVerified] = useState(false);
  const [nav, setNav] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [type, settype] = useState("");
  const [message, setMessage] = useState("");


  const loginHandler = () => {
    setClick(true);
    customRequest.post("/api/otp_login/", {
      "mobile_number": userInfo?.mobile_number,
      "user_id": userInfo?.user_id,
      "otp": otp
    })
      .then(response => {
        if (response.status === 200) {
          setVerified(true);
          const token = response?.data?.token;
          settype("success")
          setMessage(response.data.message)
          localStorage.setItem("accessToken", token);
          localStorage.setItem('user', JSON.stringify(token))
          window.location.reload();
        } else {
          settype("warning")
          setMessage(response.data.message)
        }
      })
      .catch(e => (settype("error"), setMessage(e)))
  }

  const otpResendHandler = () => {

    customRequest.post("/api/generate_otp/", {
      "mobile_number": userInfo?.mobile_number,
      "user_id": userInfo?.user_id,

    })
      .then(response => {
        if (response.status === 200) {
          toast.success("OTP Send Success");
          setWaitTime(30)
        }

        // setNav(true)
      })
      .catch(e => console.log(e))
  }






  useEffect(() => {

    let myInterval = setInterval(
      () => {

        if (Number(waitTime) > 0) {
          setWaitTime((prev) => prev - 1);
        } else {
          clearInterval(myInterval)
        }
      },
      1000
    );
    return () => clearInterval(myInterval)
  }, [waitTime]);




  return (
    <div className={`bg-[#d6e9ec] h-screen font-[Inter] relative ${verified && 'bg-white'}`}>
      <Toaster />
      {!verified && nav && <i className="fa-solid fa-arrow-left text-2xl absolute left-5 top-10" onClick={() => setNav(false)}></i>}
      {!verified && <div>
        <div className='px-12 pt-8'>
          <img src={wbpcb} className='w-fit' />
        </div>
        <div className='font-bold text-base text-center mt-6 uppercase'>
          WBPCB BRICK KILN SURVEY FORM
        </div>
      </div>}
      {/* First Mobile no page & then otp page render */}
      {!nav ? <Login setNav={(ele) => {
        setNav(ele);
        setWaitTime(30)
      }} setMobile={setUserInfo} setOtp={setOtp} /> :
        <div className={`relative  rounded-tl-3xl rounded-tr-3xl mt-16 p-10 h-[61%] ${!verified && 'bg-white'}`}>
          {click &&
            <div className="absolute inline-flex justify-center items-center w-full h-full right-1">
              {!verified ? <Loader /> :
                <div className="flex flex-col items-center space-y-5">
                  <i className="fa-solid fa-circle-check fa-beat-fade text-green-600 text-8xl"></i>
                  <div className="text-green-600 text-3xl whitespace-nowrap">Verification Successfull</div>
                </div>
              }
            </div>}
          <div className={`form ${click && 'bg-gray-300 invisible'} py-8 rounded-2xl`}>

            <div className="text-xl font-bold text-center mb-2">Verify</div>
            <svg className="check" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" xmlSpace="preserve">  <image id="image0" width="60" height="60" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
              AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
              cwAACxMAAAsTAQCanBgAAAAHdElNRQfnAg0NDzN/r+StAAACR0lEQVRYw+3Yy2sTURTH8W+bNgVf
              aGhFaxNiAoJou3FVEUQE1yL031BEROjCnf4PLlxILZSGYncuiiC48AEKxghaNGiliAojiBWZNnNd
              xDza3pl77jyCyPzO8ubcT85wmUkG0qT539In+MwgoxQoUqDAKDn2kSNLlp3AGi4uDt9xWOUTK3xg
              hVU2wsIZSkxwnHHGKZOxHKfBe6rUqFGlTkPaVmKGn6iYao1ZyhK2zJfY0FZ9ldBzsbMKxZwZjn/e
              5szGw6UsD5I0W6T+hBhjUjiF7bNInjz37Ruj3igGABjbtpIo3GIh30u4ww5wr3fwfJvNcFeznhBs
              YgXw70TYX2bY/ulkZhWfzfBbTdtrzjPFsvFI+T/L35jhp5q2owDs51VIVvHYDM9sa/LY8XdtKy1l
              FXfM8FVN2/X2ajctZxVXzPA5TZvHpfb6CFXxkerUWTOcY11LX9w0tc20inX2mmF4qG3upnNWrOKB
              hIXLPu3dF1x+kRWq6ysHpkjDl+7eQjatYoOCDIZF3006U0unVSxIWTgTsI3HNP3soSJkFaflMDwL
              3OoHrph9YsPCJJ5466DyOGUHY3Epg2rWloUxnMjsNw7aw3AhMjwVhgW4HYm9FZaFQZ/bp6QeMRQe
              hhHehWKXGY7CAuSpW7MfKUZlAUqWdJ3DcbAAB3guZl9yKC4WYLfmT4muFtgVJwvQx7T2t0mnXK6J
              XlGGyAQvfNkaJ5JBmxnipubJ5HKDbJJsM0eY38QucSx5tJWTVHBwqDDZOzRNmn87fwDoyM4J2hRz
              NgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMi0xM1QxMzoxNTo1MCswMDowMKC8JaoAAAAldEVY
              dGRhdGU6bW9kaWZ5ADIwMjMtMDItMTNUMTM6MTU6NTArMDA6MDDR4Z0WAAAAKHRFWHRkYXRlOnRp
              bWVzdGFtcAAyMDIzLTAyLTEzVDEzOjE1OjUxKzAwOjAwIIO3fQAAAABJRU5ErkJggg=="></image>
            </svg>
            <OtpInput
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
              }}
              inputStyle={{ width: "30px", height: "30px" }}
              value={otp}
              onChange={setOtp}
              className="border-2 rounded outline-none mt-7"
            />
            <div className="flex gap-3">
              <div className="flex justify-between w-full px-10 mt-6 gap-2">
                <div className="text-[#A3A3A3] text-base">Didn’t receive an OTP?</div>
                {waitTime !== 0 ?
                  <div className="text-red-500 text-base">
                    {waitTime < 10 ? `0${waitTime}` : waitTime} sec
                  </div>
                  :
                  <div className="text-base underline font-semibold" onClick={otpResendHandler}>Resend</div> //otpResendHandler
                }
              </div>
            </div>
            <div className={`w-full flex justify-center mt-8 }`}>
              {!click && <button className="w-full rounded bg-[#d6e9ec] p-[0.7rem] mx-10 font-semibold" onClick={loginHandler}>
                <span className="button-content">Submit</span>
              </button>}
            </div>
          </div>
        </div>}
    </div>
  );
};

export default OtpModal;
