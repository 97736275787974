import React, { useState, useEffect } from "react";
import { customRequest } from "../../Data/auth";
import axios from "axios";
import LocationModal from "../LocationModal/LocationModal";
import AutoComplete from "../AutoComplete";
import toast from "react-hot-toast";

export default function Login({ setNav, setMobile, setOtp }) {
  const [state, setState] = useState({ mobile_no: "" });
  const [selectedCSEID, setSelectedCSEID] = useState();
  const [userList, setUserList] = useState([])
  const [loc, setLoc] = useState(true);

  const successCallback = (position) => {
    let api_key = "7ae3daa9f6d1400cbfaf1262e653f499";
    let api_url = "https://api.opencagedata.com/geocode/v1/json";
    let req_url =
      api_url +
      "?" +
      "key=" +
      api_key +
      "&q=" +
      encodeURIComponent(
        position?.coords.latitude + "," + position?.coords.longitude
      ) +
      "&pretty=1" +
      "&no_annotation=1" +
      "&language=en";
    axios
      .get(req_url)
      .then((response) => (setLoc(response.data?.results[0].formatted), localStorage.setItem('loc', response.data?.results[0].formatted)))
      .catch((error) => console.log(error.message));
  };

  navigator.geolocation.watchPosition((position) => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    console.log(position.coords.latitude, position.coords.longitude);
  });

  const errorCallback = (error) => {
    console.log(error);
  };


  const getCSEIDs = async () => {
    const res = await fetch("https://brick-klin.wbpcb.gov.in/api/admin/user_stats", {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => setUserList(result?.data?.map((item) => ({ label: item?.user_id }))))   //setUserList(result)
      .catch(error => console.log('error', error));
  }



  useEffect(() => {
    getCSEIDs()
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);




  const otpHandler = () => {
    if (selectedCSEID?.label && state.mobile_no) {


      customRequest
        .post("/api/generate_otp/", {
          mobile_number: state.mobile_no,
          "user_id": selectedCSEID?.label
        })
        .then((response) => {
          // console.log(response);
          setNav(true);
          setMobile({ mobile_number: state.mobile_no, user_id: selectedCSEID?.label });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Provide correct mobile number!")
        });
    } else {
      if (!selectedCSEID?.label) {
        toast.error("Pleace select CSE ID from dropdown!")
      } else {
        toast.error("Pleace provide mobile!")
      }
    }
  };

  return (
    <div className="bg-white rounded-lg  mt-10 px-4  py-6  ">
      <div className="flex flex-col gap-10 pt-2">

        <div className="flex flex-col">
          <h1 className='py-3 text-[15px] text-clip font-semibold'>CSC ID</h1>
          <AutoComplete data={userList} preValue={""} onClick={(e) => { setSelectedCSEID(e); localStorage.setItem("cseId", JSON.parse(e?.label)) }} />

          {/*  */}
          <h1 className='py-3 text-[15px] text-clip font-semibold'>Mobile No.</h1>
          <input
            type={"number"}
            maxLength={10}
            placeholder="Mobile No."
            className=" outline-none p-2 border-[1px] rounded-lg"
            onInput={(e) => {
              return e.target.type === "number"
                ? (e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10))
                : e.target.value;
            }}
            name="mobile_no"
            value={state.mobile_no}
            onChange={(e) => setState({ mobile_no: e.target.value })}
          />


        </div>
        <div
          className="p-3 rounded-xl w-1/3 mx-auto font-bold bg-[#D6E9EC] text-center"
          onClick={otpHandler}
        >
          Send OTP
        </div>
      </div>

    </div>
  );
}
