import React, { useRef, useState } from 'react'
import AutoComplete from '../AutoComplete'
import UploadIcon from "../../assets/UploadIcon.png"
import { toast } from "react-hot-toast"
const Page3 = ({ values, setValues, handleChange, errors, touched, location, isDisable }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [haveFiles, setHaveFiles] = useState(values?.is_wbpcb_cte_cto);


    const fileInputRef = useRef(null);
    const handleImageChange = (event) => {

        if (event.target.files.length < 5) {
            setSelectedFiles([...event.target.files]);
            setValues({ ...values, enterprise_photo: [...event.target.files] })
        } else {
            toast.error("Maximum 4 Photos allowed.")
        }

    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <div>
            {/* Question 18*/}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>18. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Is Fly-ash being mixed?</h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors?.is_fly_ash_mixed} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setValues({ ...values, is_fly_ash_mixed: e?.label }) }} preValue={values?.is_fly_ash_mixed} />

                {values?.is_fly_ash_mixed === "Yes" && <div >
                    <div className='py-1'>
                        <div className=' flex'>
                            <h1 className='py-[2px] text-lg mr-1'>*. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Source of Fly-ash?</h1>
                        </div>
                        <input disabled={isDisable} name='fly_ash_source' value={values?.fly_ash_source} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />
                    </div>
                    <div className='py-1'>
                        <div className=' flex'>
                            <h1 className='py-[2px] text-lg mr-1'>*. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>What the %age of fly-ash being
                                mixed?</h1>
                        </div>
                        <input disabled={isDisable} name='fly_ash_percent' value={values?.fly_ash_percent} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />
                    </div>
                </div>}

            </div>
            {/* Question 19*/}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>19. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>What type of fuel is used?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='fuel_type' value={values?.fuel_type} onChange={handleChange} className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.fuel_type && "border-red-600"}`} placeholder='' />
            </div>

            {/* Question 20*/}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>20. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Source of Fuel?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='fuel_source' value={values?.fuel_source} onChange={handleChange} className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.fuel_source && "border-red-600"}`} placeholder='' />
            </div>
            {/* Question 21 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>21. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>How many people are working (no. of employees) ?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='employee_count' value={values?.employee_count} onChange={handleChange} type='number' className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.employee_count && "border-red-600"}`} placeholder='' />

            </div>
            {/* Question 22 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>22. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>How many No. of Male Workers ?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='male_worker_count' value={values?.male_worker_count} onChange={handleChange} type='number' className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.male_worker_count && "border-red-600"}`} placeholder='' />

            </div>
            {/* Question  */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>*. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>How many No. of Female Workers ??<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='female_worker_count' value={values?.female_worker_count} onChange={handleChange} type='number' className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.female_worker_count && "border-red-600"}`} placeholder='' />

            </div>
            {/* Question * */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>*. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>How many No. of Others Workers ?<span className="text-red-600">*</span></h1>
                </div>
                <input disabled={isDisable} name='other_worker_count' value={values?.other_worker_count} onChange={handleChange} type='number' className={`bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors.other_worker_count && "border-red-600"}`} placeholder='' />

            </div>
            {/* Question 23 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>23. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Toilet facility available?<span className="text-red-600">*</span></h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors.is_toilet_available} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setValues({ ...values, is_toilet_available: e?.label }) }} preValue={values?.is_toilet_available} />


            </div>
            {/* Question */}
            {<div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'></h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Toilet facility separate for Male or Female?<span className="text-red-600">*</span></h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors.is_toilet_separate} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setValues({ ...values, is_toilet_separate: e?.label }) }} preValue={values?.is_toilet_separate} />


            </div>}

            {/* Question 24 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>24. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Whether any Air Pollution Control device installed or not?<span className="text-red-600">*</span></h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors.is_air_pollution_control_device_installed} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setValues({ ...values, is_air_pollution_control_device_installed: e?.label }) }} preValue={values?.is_air_pollution_control_device_installed} />


            </div>

            {values?.is_air_pollution_control_device_installed === "Yes" && <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>*. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Please give
                        details, like type of instrument, model, make, etc?</h1>
                </div>
                <input disabled={isDisable} name='air_pollution_control_device_details' value={values?.air_pollution_control_device_details} onChange={handleChange} className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />


            </div>}


            {/* Question 25 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>25. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Is Child labour being used?<span className="text-red-600">*</span></h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors?.is_child_labour_used} title={""} data={[{ label: "Yes", }, { label: "No" }]} onClick={(e) => { setValues({ ...values, is_child_labour_used: e?.label }) }} preValue={values?.is_child_labour_used} />


            </div>

            {/* Question 26 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>26. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Is the Chimney Fixed or Moveable?<span className="text-red-600">*</span></h1>
                </div>
                <AutoComplete isDisable={isDisable} isError={errors?.is_furnace_fixed} title={""} data={[{ label: "Fixed", }, { label: "Moveable" }]} onClick={(e) => { setValues({ ...values, is_furnace_fixed: e?.label }) }} preValue={values?.is_furnace_fixed} />



            </div>

            {/* Question 27 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>27. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Approx area in Square Meters?</h1>
                </div>
                <input disabled={isDisable} type='number' name='area' value={values?.area} onChange={handleChange} className={` bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full ${errors?.area && "border-red-600"}`} placeholder='' />

            </div>

            {/* Question 28 */}
            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>28. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Captured Lat, Long automatically from Location?<span className="text-red-600">*</span></h1>
                </div>
                <div className=' grid grid-cols-2 gap-4'>
                    <input disabled={true} value={location?.latitude} className=' bg-[#f2f2f2]  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />
                    <input disabled={true} value={location?.longitude} className=' bg-[#f2f2f2]  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2  w-full' placeholder='' />

                </div>
            </div>




            {/* Question 29 */}

            <div className='py-1'>
                <div className=' flex'>
                    <h1 className='py-[2px] text-lg mr-1'>29. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>Take photos of the enterprise & selfie with enterprise ( maximum 4)?<span className="text-red-600">*</span></h1>
                </div>

                {values?.enterprise_photo ? (
                    values?.enterprise_photo?.map((item, index) => <img
                        key={index}
                        src={URL.createObjectURL(item)}
                        alt="Selected"

                        style={{ maxWidth: '300px' }}
                    />)
                ) : values?.enterprise_photo_path && values?.enterprise_photo_path?.map((item, index) => <img key={index} src={`https://brick-klin.wbpcb.gov.in/api/static?path=${item}`} className="pt-2 w-40 h-40" />)}



                <input
                    disabled={isDisable}
                    multiple
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="mx-4 px-4 text-md text-white "
                    style={{ display: "none" }}
                    ref={fileInputRef}
                />

                <div className={` border-[2px] border-[#d5d5d5] h-12 bg-white rounded-lg truncate flex  justify-between items-center px-4 text-[#4c9daf] ${errors?.enterprise_photo && "border-red-600"}`} onClick={handleButtonClick}>
                    {selectedFiles?.length > 0 ? selectedFiles?.map((item, index) => item.name) : "Upload file"}
                    <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
                </div>
            </div>








        </div >
    )
}

export default Page3