import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { lazy, Suspense } from 'react';
import Home from './Home';
import PreviousDetails from '../components/PreviousFormDetails/PreviousDetails';
// import Form from '../components/Form/Form'
import Preview from './Preview';
import BrickKilnFrom from '../components/Form/BrickKilnFrom'


export default function Navigation() {
    return (
        <Router>

            <Routes >
                <Route path="/" element={<Home />} />
                {/* <Route path="/preview" element={<Preview />} /> */}
                <Route path="/addNew" element={<BrickKilnFrom />} />
                {/* <Route path="/update_form" element={<Form />} />
                    <Route path="/update_form_preview" element={<Preview />} />
                    <Route path="/previous_details" element={<PreviousDetails />} /> */}
            </Routes>
        </Router>
    )
}
