import React from 'react';

const ProgressBar = ({ percent }) => {
    const gradientColor = `linear-gradient(90deg,#38bdf8 ${percent}%, transparent ${percent}%)`;

    return (
        <div className="relative pt-1">

            <div className="flex flex-wrap items-center">
                <div className="w-full">
                    <div className="relative w-full h-2 bg-teal-200">
                        <div
                            className="absolute top-0 h-2"
                            style={{
                                background: gradientColor,
                                width: '100%',
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
